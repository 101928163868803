import React, { useRef, useEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
function LocationSearch(props: {
  currentStep: number;
}) {
  useEffect(() => {

  });

  return (
    <div className="sidebar p-5">
      <div className='p-3 rounded-3 text-light bg-normalprimary d-flex align-items-center shadow'>
        <div style={{ width: '15em' }}>
          <h2 className='fs-5 fw-normal'>Locate Region</h2>
          <form>
            <div id='geocoder-container' placeholder='Search for an address, place, state, etc' />
          </form>
        </div>
      </div>
    </div>
  )
}

export default LocationSearch